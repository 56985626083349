<template>
  <div>
    <div class="market__content">
      <!--Buttons-->
      <b-row>
        <b-col
          cols="12"
          sm="6"
          lg="3"
          v-for="(item, index) in buttonOptions"
          :key="index"
          class="my-1"
        >
          <b-link
            @click="updateSelectedIndex(index)"
            class="d-flex"        
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="lg"
              :disabled="index !== selectedIndex"
              class="btn-icon"
            >
              <feather-icon :icon="item.icon" />
            </b-button>
            <div class="ml-1">
              <div style="font-size: 16px; font-weight: 700">
                {{ item.title }}
              </div>
              <div style="font-size: 14px">{{ item.text }}</div>
            </div>
          </b-link>
        </b-col>
      </b-row>

      <b-card class="mt-2">
        <div style="min-height: 450px">
          <div v-if="selectedIndex == 0">
            <account-setup />
          </div>
          <div v-if="selectedIndex == 1 && marketSetup">
            <market-setup />
          </div>
          <div v-if="selectedIndex == 2 && beginIntergration">
            <start-intergration />
          </div>
        </div>
        <div class="button__container">
          <div v-if="selectedIndex === 0"></div>
          <b-button
            v-if="selectedIndex > 0"
            @click="previous"
            variant="primary"
            class="mb-1"
          >
            Previous
          </b-button>
          <b-button @click="next" variant="primary" class="mb-1"
            >{{ selectedIndex === 2 ? "Finish" : "Continue" }}
          </b-button>
        </div>
      </b-card>
    </div>

    <div class="market__alert">
      <b-alert variant="secondary" show>
        <div
          style="
            display: flex;
            padding: 1rem;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <p style="font-size: 16px">Do you need help!</p>
            <p>We are here to provide all the support you need.</p>
          </div>
          <b-button
            @click="$router.push({ name: 'apps-contact' })"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          >
            <span>&nbsp;Contact us</span>
          </b-button>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import AccountSetup from "../account-setup/AccountSetup.vue";
import MarketSetup from "../market-setup/MarketSetup.vue";
import StartIntergration from "../start-intergration/StartIntergration.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BLink,
  BAlert,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BAlert,
    vSelect,
    AccountSetup,
    MarketSetup,
    StartIntergration,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      buttonOptions: [
        {
          icon: "ShoppingCartIcon",
          title: "Account Setup",
          text: "Start account setup",
        },
        {
          icon: "HomeIcon",
          title: "Marketplace Setup",
          text: "Enter your address",
        },
        {
          icon: "CreditCardIcon",
          title: "Start Intergration",
          text: "Enter your address",
        },
      ],
      selectedIndex: 0,
      marketSetup: false,
      beginIntergration: false,
    };
  },
  methods: {
    updateSelectedIndex(index) {
      if (this.selectedIndex == 0) {
        this.checkAccountSetupCompletion();
        if (this.marketSetup) {
          this.selectedIndex = index;
        }
      } else if (this.selectedIndex == 1) {
        this.checkMarketSetupCompletion();
        if (this.beginIntergration) {
          this.selectedIndex = index;
        }
      } else {
        this.selectedIndex = index;
      }
    },
    next() {
      // check if we have reached the end
      if (this.selectedIndex == 2) return;
      // check market intergration
      this.checkMarketIntergration();
      if (this.selectedIndex == 0 && this.marketSetup) {
        this.selectedIndex++;
      } else if (this.selectedIndex == 1 && this.beginIntergration) {
        this.selectedIndex++;
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Welcome to Automatum`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `Please we need you to complete the following before going to next step: Create account, Login into the account and create a seller account.`,
          },
        });
        return;
      }
    },
    previous() {
      if (this.selectedIndex == 0) return;
      this.selectedIndex--;
    },
    getColor(index) {
      if (this.selectedIndex == index) {
        return "black";
      } else {
        return "#9CA3AF";
      }
    },
    checkMarketIntergration() {
      switch (this.selectedIndex) {
        case 0:
          this.checkAccountSetupCompletion();
          break;
        case 1:
          this.checkMarketSetupCompletion();
          break;
        default:
          break;
      }
    },
    checkAccountSetupCompletion() {
      const value = this.$store.state["app-market"].marketIntergration;
      const response = value.filter(
        (e) =>
          e == "aws__account__created" ||
          e == "aws__account__logged__in" ||
          e == "seller__account__registration"
      );
      console.log("[[Checked]]", response);
      if (response.length == 3) {
        this.marketSetup = true;
      } else {
        this.marketSetup = false;
      }
    },
    checkMarketSetupCompletion() {
      const value = this.$store.state["app-market"].marketIntergration;
      const response = value.filter(
        (e) =>
          e == "settings__instructions" ||
          e == "payment__instructions" ||
          e == "account__instructions"
      );
      if (response.length == 3) {
        this.beginIntergration = true;
      } else {
        this.beginIntergration = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/ui-feather.scss";
</style>

<style lang="css" scoped>
.market__content {
  min-height: 100vh;
}
.button__container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
</style>


